
.a-section--stabiluren {
  background-color: #fff;
  overflow: hidden;

  @include respondDf(beforeFullHd) {
    height: auto !important;
  }

  h2 {
    text-align: left;
    margin: 0;
    line-height: 1;
  }

  & h2 ~ h3 {
    margin-top: 0;
  }

  h3 {
    margin-bottom: 4rem;
    font-size: 3.6rem;
    color: $color-5;
  }

  .a-col--5 {
    display: flex;
    align-items: center;

    @include respondDf(md) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }

    img {

      @include respondDf(md) {
        max-width: 100%;
        margin: 0 0 3rem;
      }
    }
  }

  .a-col--7 {

    @include respondDf(md) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}

.a-section--about-stabiluren {

  .a-row {
    align-items: center;
  }

  .a-col--6 {

    @include respondDf(beforeFullHd) {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
      margin-right: 0;
    }

    @include respondDf(lg) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }

  .a-col--5 {

    @include respondDf(beforeFullHd) {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
    }

    @include respondDf(lg) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }

  h2 {
    line-height: 1;
    margin: 0 0 5rem
  }

  h3 {
    color: #000;

    @include respondDf(lg) {
      text-align: center;
    }
  }

  ul {
    font-size: 2.6rem;
    line-height: 1.38;

    @include respondDf(beforeFullHd) {
      font-size: 2.1rem;
    }
  }

  li::before {
    top: 1rem;
    background-image: url('#{$path-image}/layout/list-blue.png');

    @include respondDf(beforeFullHd) {
      top: .7rem;
    }
  }
}

.a-section--why-stabiluren {
  align-items: center;
}

.a-section--why-stabiluren {

  .a-row {
    align-items: center;
  }

  .a-col--7 {

    @include respondDf(beforeFullHd) {
      margin-left: 0;
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
    }

    @include respondDf(lg) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }

  .a-col--5 {
    left: 5rem;

    @include respondDf(beforeFullHd) {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
    }

    @include respondDf(lg) {
      left: 0;
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }

    h2, p {
      text-align: center;

      @include respondDf(xlg) {
        text-align: left;
      }
    }

    h2 {
      margin: 0 0 1rem;
    }
  }

  h2 {
    text-align: left;
    font-size: 3.6rem;
    line-height: 1.333;
    color: $color-5;

    @include respondDf(beforeFullHd) {
      font-size: 3rem;
    }
  }

  h2 ~ h2 {
    margin-top: 0;
  }

  h2 img {
    width: 17rem;

    @include respondDf(beforeFullHd) {
      width: 14rem;
    }
  }

  h2 + p {
    font-size: 2rem;
    color: $color-3;
  }

  ul {
    font-size: 2.6rem;
    line-height: 1.38;

    @include respondDf(beforeFullHd) {
      font-size: 2.1rem;
    }
  }

  li::before {
    top: 1rem;
    background-image: url('#{$path-image}/layout/list-blue.png');
  }
}

.a-section--stabiluren h2 img,
.a-section--about-stabiluren h2 img {
  width: 26.8rem;
}
