
.a-section--home {

  .a-col {

    @include respondDf(md) {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
  }

  h1 {

    @media only screen and (max-width: 1440px) {
      margin: -5rem 0 2rem;
      white-space: nowrap;
    }
    @media only screen and (max-width: 1150px) {
      white-space: normal;
    }
    @media only screen and (max-width: 767px) {
      margin: 0 0 2rem;
    }
    @media only screen and (max-width: 375px) {
      margin: -5rem 0 2rem;
      font-size: 3rem;

      @media screen and (orientation:landscape) {
        margin: 0 0 2rem;
      }
    }
  }

  .a-subtitle {

    @media only screen and (max-width: 1366px) {
      .im-br {
        display: block;
        line-height: 0;
        height: 0;
        max-height: 0;
      }
    }

    p {
      @media only screen and (max-width: 1366px) {
        line-height: 1.5;
      }
      @media only screen and (max-width: 375px) {
        font-size: 2rem;
      }
    }
  }
}
