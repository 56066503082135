
.a-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.a-list-item {
  position: relative;
  left: -1rem;
  display: flex;
  align-items: flex-start;
  flex: 0 0 50%;

  @media only screen and (max-width: 1024px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }

  &:first-of-type {
    @media only screen and (max-width: 1024px) {
      order: 1;
    }
  }
  &:nth-of-type(2) {
    @media only screen and (max-width: 1024px) {
      order: 3;
    }
  }
  &:nth-of-type(3) {
    @media only screen and (max-width: 1024px) {
      order: 2;
    }
  }
  &:nth-of-type(4) {
    @media only screen and (max-width: 1024px) {
      order: 4;
    }
  }
}

.a-list-item__left {
  flex: 0 0 6rem;
}

.a-list-item__num {
  position: relative;
  top: .3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: .2rem solid $color-2;
  font-family: $font-family-2;
  font-size: 1.8rem;
  font-weight: 700;
  color: $color-1;
}

.a-list-item__inner ul {
  @media only screen and (max-width: 767px) {
    margin: 2rem 0 2rem -5rem;
  }
}

