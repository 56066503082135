
.a-section--contact {
  position: relative;
  align-items: flex-start;

  @media only screen and (min-width: 1920px) {
    padding-top: 20rem;
  }

  @include respondDf(beforeMacbook) {
    padding-top: 9rem;
  }

  @media only screen and (max-width: 1024px) {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 8rem;
    align-items: flex-start;
  }

  @media only screen and (max-width: 767px) {
    padding-bottom: 45rem;
  }

  @include respondDf(md) {
    flex-wrap: wrap;
  }

  h2 {
    margin: 0 0 2rem;
    text-align: left;
    font-size: 3.6rem;
    line-height: 1.333;
    color: $color-5;
  }

  h3 {
    margin: 0 0 1rem;
    font-size: 3rem;
    color: $color-5;
  }

  p {
    font-size: 1.5rem;
    line-height: 2.4;
  }

  > img {
    object-position: center bottom;
    font-family: 'object-fit:cover;object-position:center bottom;';
  }

  .a-col--6 {

    @include respondDf(md) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      margin-bottom: 3rem;
    }
  }
}


