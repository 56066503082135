
.a-header {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 31.55rem);
  z-index: 100;
  padding: 5rem 4.5rem 0;

  @include respondDf(xl) {
    z-index: 1000;
  }
  @include respondDf(beforeMacbook) {
    padding: 2rem 0 0;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  &--absolute {
    position: absolute;
    z-index: 100;
    width: 100%;
  }
}

.a-header .a-container {
  max-width: 100%;
}

.a-header__subtitle {
  font-size: 2.4rem;
  font-weight: 700;
  color: $color-1;

  @media only screen and (max-width: 1024px) {
    padding-right: 7rem;
  }

  @include respondDf(md) {
    font-size: 1.4rem;
  }
}

.a-header .a-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
