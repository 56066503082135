
.a-section--why {
  background-color: $color-4;

  @include respondDf(beforeMacbook) {
    display: flex;
    align-items: center;
  }

  @include respondDf(lg) {
    overflow: hidden;
  }

  h2 {
    margin-bottom: 2rem;
  }
  p {
    @include respondDf(beforeMacbook) {
      font-size: 1.4rem;
    }
  }

  .a-col {

    @include respondDf(md) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}

.a-section--why .a-row {
  position: relative;
}