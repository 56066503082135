
.a-subtitle p {
  font-size: 4rem;
  line-height: 8rem;
  color: $color-3;

  @include respondDf(beforeFullHd) {
    font-size: 3rem;
    line-height: 6rem;
  }

  @include respondDf(md) {
    font-size: 2.6rem;
    line-height: 1.2;
  }
}

.a-subtitle--3 p {
  font-size: 3rem;
  line-height: 1.5;

  @include respondDf(beforeFullHd) {
    font-size: 2.4rem;
  }
}

.a-subtitle--26 p {
  font-size: 2.6rem;
  line-height: 1.4;

  @include respondDf(beforeFullHd) {
    font-size: 2rem;
  }
}

.a-subtitle small {
  font-size: 3rem;

  @include respondDf(beforeFullHd) {
    font-size: 2.6rem;
  }
}
