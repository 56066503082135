
@keyframes diagram {
  0% { width: 0; }
  100% { width: 100%; }
}

.a-diagram {
  position: relative;
  transform-origin: left;
  height: 28rem;
  margin: 0 0 0 5%;
  overflow: hidden;

  @include respondDf(md) {
    height: auto;
    margin-left: 0;
    margin-bottom: 4rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;

    @include respondDf(beforeFullHd) {
      width: 35rem !important;
    }

    @media only screen and (max-width: 1366px) {
      width: 27rem !important;
    }

    @include respondDf(lg) {
      position: static;
      width: auto !important;
      max-width: 100%;
    }
  }
}

.a-diagram--without {
  height: 25rem;
  margin: -1rem 0 0 4rem;

  @media only screen and (max-width: 1366px) {
    margin: 2rem 0 0 4rem;
  }

  @include respondDf(lg) {
    height: auto;
    margin: 1rem 0 5rem;
    text-align: center;
  }

  img {
    width: 45rem;
  }
}

.a-diagram--with {
  height: 32rem;
  margin: 0 0 0 4rem;

  @include respondDf(lg) {
    height: auto;
    margin: 1rem 0 5rem;
    text-align: center;
  }

  img {
    width: 45rem;
  }
}

.a-diagram--safety {
  margin: 0;

  @include respondDf(beforeFullHd) {
    margin: 0 0 0 5%;
  }
  @include respondDf(lg) {
    margin: 0;
    text-align: center;
  }

  img {
    width: 50rem;

    @include respondDf(beforeFullHd) {
      width: 42rem;
    }

    @include respondDf(beforeMacbook) {
      width: 39rem;
    }

    @media only screen and (max-width: 1366px) {
      width: 31rem;
    }

    @include respondDf(lg) {
      width: auto;
    }
  }
}

.a-section.active .a-diagram {
  width: 0;
  animation: 5s diagram forwards;

  @include respondDf(lg) {
    width: 100%;
    animation: none;
  }
}
